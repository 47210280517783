* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #282c34;
}

::-webkit-scrollbar{
  scroll-behavior: smooth;
  display: none;
}

.App-header {
  display: grid;
  grid-template-columns: 50% 100%;
  height: 90vh;
}

.intro-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro-container p {
  color: white;
  font-size: 3vw;
  font-weight: 700;
}

.intro-container h1 {
  font-size: 5vw;
  background: linear-gradient(90deg, #ff0000, #ffff00, #ff00f3, #0033ff, #ff00c4, #ff0000);
  background-size: 400%;
  color: transparent;
  background-clip: text;
  animation: animate 10s linear infinite;
}

@keyframes animate {
  0%{
    background-position: 0%
  }
  100%{
    background-position: 400%
  }
}

.photo-container {
  width: 50%;
  margin-top: 7%;
}

.photo {
  margin: 0 0 0 30%;
  width: 50%;
  min-width: 100px;
}

.social-media-container {
  margin: 0 20% 0 30%;
  display: grid;
  grid-template-columns: 50% 50%;
}

.social-media {
  width: 50%;
  min-width: 30px;
  border-radius: 100%;
  margin-left: 25%;
}

.media-logo {
  width: 100%;
  min-width: 30px;
  border-radius: 100%;
}

.projects {
  display: inline;
}

.title-container {
  background-color: rgb(13, 218, 139);
  height: 10vh;
  text-align: center;
}

.projects-title {
  color: white;
  text-shadow: black 2px 2px 2px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 60px;
  padding-top: 0.5%;
}

.projects-presentation {
  color: white;
  font-size: 20px;
  margin: 20px 0;
  text-align: center;
}

.projects-container {
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
}

.project {
  background-color: white;
  justify-content: center;
  text-align: center;
  border: solid white 2px;
  margin: 2% 20%;
}

.project-img {
  width: 100%;
  object-fit: contain;
}

.project-name {
  padding: 10px;
}

.project-desc {
  padding: 10px 5px;
}
